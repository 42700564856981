import { Link } from "@mui/material";
import Page from "../../components/Page"
import { listData } from "../../master/libraryData";
import { SaveableBase } from "../../model/character";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Section from "../../components/Section";

import AddIcon from '@mui/icons-material/Add';

interface LibraryItemProps {
    item: SaveableBase;
    path: string;
    navigate: NavigateFunction;
}

const LibraryItem = (props: LibraryItemProps) => {
    const { item, path, navigate } = props;
    return <div><Link href="#" onClick={() => navigate(path)}>{item.name}</Link></div>
}

interface LibraryListProps {
    title: string;
    path: string;
    items: SaveableBase[];
}

const LibraryList = (props: LibraryListProps) =>{
    const navigate = useNavigate();
    return <Section title={props.title} actions={[
        {
            action: () => navigate(props.path),
            label: <AddIcon></AddIcon>
        }
    ]}>
        {props.items.map((item) => <LibraryItem navigate={navigate} item={item} path={`${props.path}/${item.id}`} />)}
    </Section>
}

export const Library = () => {
    const weaponMods = listData("lwm");
    const weaponVars = listData("lwv");
    const weapons = listData("lw");

    const outfitMods = listData("lam");
    const outfitVars = listData("lav");
    const outfits = listData("la");


    return <Page>
        <h2>Library</h2>

        <LibraryList items={weapons} path="/library/weapon" title="Weapons" />
        <LibraryList items={weaponMods} path="/library/weaponMod" title="Weapon Mods" />
        <LibraryList items={weaponVars} path="/library/weaponVariation" title="Weapon Variations" />

        <LibraryList items={outfits} path="/library/outfit" title="Outfits" />

        </Page>
}